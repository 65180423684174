<template>
	<div class="page">
		<div style="margin-top: 30px;position: relative;">
			<iframe src="https://wancode.taobao.com" frameborder="0" width="1280px" height="500px"></iframe>
			<div @click="jumpToTaoBao" style="width: 1280px;height: 500px;background-color: #FFFFFF;opacity: 0;position: absolute;top: 0;left: 0;z-index: 998;">

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			jumpToTaoBao() {
				this.$alert('即将跳转至淘宝网', '跳转', {
					confirmButtonText: '确定',
					callback: action => {
						var tempwindow = window.open();
						tempwindow.location = 'https://wancode.taobao.com';
					}
				})
			}
		}
	};
</script>

<style scoped></style>
